import React from "react"
import { graphql } from "gatsby"
import SEO from "../../components/seo"
import Layout from "../../components/layout"

const VideoPage = ({ data }) => {
  return (
    <Layout title="Video">
      <SEO title="Video | Leonie Freudenberger" />
      <article className="tabbed-content">
        <div className="video-content">
          {data.allContentfulVideo.nodes.map(video => (
            <div
              className="entry fade-in-1"
              data-sal="fade"
              data-sal-duration="1000"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: video.embedCode.childMarkdownRemark.html,
                }}
              ></div>
              <div className="details">
                <h4>{video.title}</h4>
                {video.credits !== null && (
                  <div
                    className="credits"
                    dangerouslySetInnerHTML={{
                      __html: video.credits.childMarkdownRemark.html,
                    }}
                  ></div>
                )}
                {video.description !== null && (
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{
                      __html: video.description.childMarkdownRemark.html,
                    }}
                  ></div>
                )}
              </div>
            </div>
          ))}
        </div>
      </article>
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulVideo(sort: { fields: releaseDate, order: DESC }) {
      nodes {
        title
        credits {
          childMarkdownRemark {
            html
          }
        }
        description {
          childMarkdownRemark {
            html
          }
        }
        embedCode {
          childMarkdownRemark {
            html
          }
        }
        releaseDate(formatString: "YYYY")
      }
    }
  }
`

export default VideoPage
